import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Location } from '@reach/router'
import route from 'path-match'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { tablet, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { easing } from '@/utils/preset'
import TextButton from './TextButton'
import { Link } from 'gatsby'
import Typography from './Typography'
import Icon from './Icon'
import LinkButton2 from './LinkButton2'
import Menu from '@material-ui/core/Menu'
import * as MenuItem from '@/components/common/MenuItem'

class Nav extends React.Component {
  constructor (props) {
    super(props)
    const state = {}
    props.routes.main.filter(route => {
      return route.childLists
    }).forEach(route => {
      state[route.path] = null
    })
    this.state = state
  }
  handleMenuClick = path => {
    return event => {
      const state = {
        ...this.state
      }
      state[path] = event.currentTarget
      this.setState(state)
    }
  }
  handleMenuClose = path => {
    return event => {
      const state = {
        ...this.state
      }
      state[path] = null
      this.setState(state)
    }
  }
  render () {
    const { theme, routes, offices, classes, ...other } = this.props
    return (
      <Root theme={theme} {...other}>
        {false && (
          <Row>
            <TelList theme={theme}>
              <TelList_Head>
                <TelList_Icon name="tel" />
                <TelList_Title variant="h5" lang="en">TEL</TelList_Title>
              </TelList_Head>
              <TelList_Main>
                {offices.map((office, index) => (
                  <TelList_Child key={index}>
                    <Tel href={'tel:' + office.tel.replace(/[^0-9]/g, '')}>
                      <Tel_Text variant="h4" component="span" lang="en">
                        <Tel_Area theme={theme}>{office.headquarter ? '本社' : office.area}：</Tel_Area><Tel_Number>{office.tel}</Tel_Number>
                      </Tel_Text>
                    </Tel>
                  </TelList_Child>
                ))}
              </TelList_Main>
            </TelList>
          </Row>
        )}
        <Row>
          <PageList>
            {routes.main.map((item, index) => {
              const match = route({ start: true, end: false })(item.path)
              return (
                <Location key={index}>
                  {({ navigate, location }) => (
                    <PageList_Child>
                      {item.childLists && item.path !== '/projects/' && (
                        <Fragment>
                          <Menu
                            open={Boolean(this.state[item.path])}
                            onClose={this.handleMenuClose(item.path)}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            anchorEl={this.state[item.path]}>
                            <MenuItem.default
                              classes={{ ...MenuItem.get(classes) }}
                              component={Link}
                              to={item.path}
                              onClick={this.handleMenuClose(item.path)}>
                              {item.indexTitle
                                ? item.indexTitle
                                : `${item.title}トップ`
                              }
                            </MenuItem.default>
                            {item.childLists.map((child, index) => {
                              return (
                                <MenuItem.default
                                  classes={{ ...MenuItem.get(classes) }}
                                  key={index}
                                  component={Link}
                                  to={child.path}
                                  onClick={this.handleMenuClose(item.path)}>
                                  {child.title}
                                </MenuItem.default>
                              )
                            })}
                          </Menu>
                          <PageLink
                            onClick={this.handleMenuClick(item.path)}>
                            {item.title}
                            <PageLink_Arrow className={match(location.pathname) ? 'active' : false} width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 0L0 8L16 8L8 0Z" fill="currentColor"/>
                            </PageLink_Arrow>
                          </PageLink>
                        </Fragment>
                      )}
                      {(!item.childLists || item.path === '/projects/') && (
                        <PageLink
                          to={item.path}
                          component={Link}>
                          {item.title}
                          <PageLink_Arrow className={match(location.pathname) ? 'active' : false} width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 0L0 8L16 8L8 0Z" fill="currentColor"/>
                          </PageLink_Arrow>
                        </PageLink>
                      )}
                    </PageList_Child>
                  )}
                </Location>
              )
            })}
          </PageList>
          <Contact to={routes.conversion[0].path} component={Link} icon="email" color="secondary">{routes.conversion[0].title}</Contact>
        </Row>
      </Root>
    )
  }
}

const Root = styled.nav`
  @media ${mq.and(tablet, mobile)} {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const TelList = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 0 8px 0 24px;
  background-color: ${props => props.theme.scale[800]};
`

const TelList_Head = styled.div`
  display: flex;
  align-items: center;
`

const TelList_Icon = styled(Icon)`
  font-size: 20px;
`

const TelList_Title = styled(Typography)`
  margin-left: 8px;
  display: none;
`

const TelList_Main = styled.div`
  margin-left: 16px;
  display: flex;
`

const TelList_Child = styled.div`
  &:not(:first-of-type) {
    margin-left: 8px;
  }
`

const Tel = styled(TextButton)``

const Tel_Text = styled(Typography)`
  display: flex;
  align-items: center;
`

const Tel_Area = styled.span`
  font-size: 0.6em;
  transform: translateY(0.06em);
`

const Tel_Number = styled.span``

const Contact = styled(LinkButton2)`
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: bold;
  width: 128px;
`

const PageList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  margin-right: 16px;
`

const PageList_Child = styled.li`
  text-align: center;
  margin: 0 4px;
`

const PageLink = styled(TextButton)`
`

const PageLink_Arrow = styled.svg`
  display: block;
  position: absolute;
  left: 50%;
  bottom: -10px;
  margin-left: -8px;
  opacity: 0;
  transform: translateY(2px);
  transition:
    opacity 0.2s ${easing.css.easeInOutExpo},
    transform 0.2s ${easing.css.easeInOutExpo};
  &.active {
    opacity: 1;
    transform: translateY(0);
  }
`

const make = props => {
  return {
    ...MenuItem.make(props)
  }
}

export default ThemeConsumer(WithStylesProps(make)(Nav))
